body {
	font-family: "Roboto", sans-serif;
	background: $white; }
main {
	margin-top: 120px;
	@include r(969) {
		margin-top: 75px; } }
.section {
	background: url(../img/svg/background.svg) no-repeat center top; }
.block10 {
	.main_block {
		.img_block {
			@include r(950) {
				max-height: 230px; }
			.logo_img-2 {
				@include r(950) {
					bottom: -20px; } } } }
	.text {
		padding-left: 10px; }
	.test {
		@include r(950) {
			margin-top: 30px; } }
	.btnback {
		@include r(950) {
			margin-top: 40px; } }
	.logo_img-2 {
		bottom: 20px; }
	.main_block {
		@include r(950) {
			padding-top: 60px; } } }
.text10 {
	margin-bottom: 20px;
	@include r(900) {
		margin-bottom: 0px; } }
.title {
	font-size: 30px;
	font-weight: 700;
	line-height: 1.1;
	color: $black;
	margin-bottom : 30px;
	max-width: 800px;
	@include r(950) {
		font-size: 20px;
		margin-bottom: 20px;
		text-align: center;
		margin-left: 0;
		width: 100%; } }
.text {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.2;
	color: $black; }
.bold {
	font-weight: 700;
	margin-bottom: 20px; }
.text_block2 {
	margin-bottom: 30px;
	margin-left: 10px; }
.btn_block {
	margin-top: auto;
	display: flex;
	@include r(900) {
		display: block; } }
.btn {
	border-radius: 300px;
	border: 2px solid $turquoise;
	padding: 20px 25px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 700;
	position: relative;
	margin: 0 10px;
	cursor: pointer;
	text-decoration: none;
	display: block;
	width: fit-content;
	@include r(950) {
		width: 100%;
		display: block;
		text-align: center; }
	@include r(360) {
		font-size: 16px; }
	&:hover {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); } }
.btnnext {
	background: $turquoise;
	color: $white;
	padding-right: 62px;
	@include r(950) {
		margin-top: 20px;
		padding: 20px 40px 20px 10px;
		&:before {
			right: 5px; } }
	&:before {
    	content: "";
    	height: 21px;
    	width: 29px;
    	right: 25px;
    	position: absolute;
    	background: url(../img/svg/next.svg) no-repeat center;
    	display: inline-block; } }
.btnback {
	background: $pale;
	color: $turquoise;
	padding-left: 62px;
	@include r(950) {
		padding: 20px 30px;
		&:before {
			left: 10px; } }
	&:before {
    	content: "";
    	height: 21px;
    	width: 29px;
    	left: 25px;
    	position: absolute;
    	background: url(../img/svg/back.svg) no-repeat center;
    	display: inline-block; } }
.container {
	max-width: 1700px;
	margin: 0 auto; }
.section {
	.container {
		@include r(1600) {
			padding: 0px 40px; }
		@include r(950) {
			max-width: 467px; }
		@include r(450) {
			padding: 0px 20px; } } }
.steps_block {
	background: $pale;
	padding: 40px;
	@include r(1050) {
		padding: 8px; }
	.step {
		display: flex;
		margin: auto;
		width: fit-content;
		position: relative;
		@include r(769) {
			width: 340px; }
		@include r(360) {
			width: 300px; }
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 5%;
			height: 2px;
			width: 90%;
			background: $turquoise;
			opacity: .2; } }
	p {
		color: $pale;
		font-weight: 700;
		background: $turquoise;
		width: 50px;
		height: 50px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: .2;
		@include r(1050) {
			width: 40px;
			height: 40px;
			font-size: 14px;
			border: 1.6px solid $white; } }
	.number {
		background: $pale;
		position: relative;
		margin: 0px 24px;
		@include r(1050) {
			margin: 0px 13.5px; }
		@include r(769) {
			margin: 0px 8.5px; }
		@include r(360) {
			margin: 0px 5px; }
		@include r(769) {
			&:nth-child(10) {
				margin-left: auto; } }
		@include r(769) {
  			@for $i from 4 through 9 {
    			&:nth-child(#{$i}) {
      				display: none; } } }
		&.active {
			p {
				opacity: 1; } }
		&.done {
			p {
				opacity: 1;
				background:url(../img/svg/done.svg) no-repeat center, $turquoise {}
				color: rgba(0, 0, 0, 0); } } } }
.block1 {
	max-width: 1440px;
	margin: auto;
	padding-top: 90px;
	@include r(950) {
		padding-top: 20px; }
	.title {
		margin-left: 0;
		max-width: 100%;
		position: static;
		transform: none; }
	.text {
		margin-bottom: 20px; } }
.img_form {
	height: fit-content; }
.flow_abs {
	position: absolute;
	bottom: -50%;
	right: -10%;
	width: 100%;
	@include r(950) {
		bottom: auto;
		top: 20%;
		right: -20%; } }
.formlabel {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.2;
	color: $gray;
	display: block;
	margin-bottom: 5px; }
.input {
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	background: #fff;
	border: 1px solid #d5dbe0;
	color: #061b2e;
	font-size: 16px;
	padding: 0 10px;
	outline: none;
	border-radius: 4px;
	&::placeholder {
		font-size: 16px;
		font-weight: 400;
		color: $gray; }
	&:focus {
		border: 1px solid $turquoise; } }
.input-wrap {
	margin-bottom: 30px;
	position: relative; }
.main_block {
	max-width: 1440px;
	display: flex;
	padding: 90px 0;
	margin: auto;
	@include r(950) {
		flex-direction: column;
		position: relative;
		padding: 20px 0 60px; }
	.img_block {
		max-width: 588px;
		max-height: 616px;
		width: 100%;
		overflow: hidden;
		border-radius: 30px;
		position: relative;
		margin-right: 15px;
		@include r(1450) {
			max-width: 500px;
			max-height: 520px; }
		@include r(1110) {
			max-width: 350px;
			max-height: 360px; }
		@include r(950) {
			margin: 0;
			max-width: 100%;
			max-height: 310px; }
		.main_img {
			border-radius: 30px;
			width: 100%;
			@include r(950) {
				margin-top: -20px; } }
		.logo_img9 {
			position: absolute;
			top: 20px;
			left: 25px;
			width: 40%;
			@include r(950) {
				display: none; } }
		.logo_img {
			position: absolute;
			top: 20px;
			right: 30px;
			width: 40%;
			@include r(950) {
				display: none; } }
		.logo_img-mob {
			display: none;
			position: absolute;
			top: 13px;
			right: 15px;
			@include r(950) {
				display: block; } }
		.logo_img-2 {
			width: 100%;
			position: absolute;
			bottom: -40px;
			right: -220px;
			@include r(1450) {
				right: -190px; }
			@include r(1110) {
				right: -120px; }
			@include r(950) {
				width: 250px;
				right: -70px;
				bottom: 20px; } } }
	.form_block {
		max-width: 467px;
		width: 100%;
		background: #EBF9F6;
		margin-left: 10px;
		border-radius: 30px;
		padding: 30px 20px 40px;
		@include r(950) {
			margin-top: -60px;
			margin-left: 0px;
			z-index: 1; }
		.btnnext {
			margin: 40px auto 0;
			display: block;
			@include r(950) {
				width: 100%; } } } }
.main_block1 {
	padding: 20px 0 60px;
	@include r(950) {
		padding: 0px 0 60px; } }
.test {
	display: flex;
	flex-direction: column;
	max-width: 830px;
	width: 100%; }
h2 {
	margin-left: 10px; }
.flex_block {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	@include r(950) {
		background: #EBF9F6;
		border-radius: 30px;
		padding: 30px 30px 40px;
		justify-content: center;
		margin-top: -60px;
		z-index: 1; } }
.custom-radio {
	display: flex;
	align-items: center;
	position: relative;
	padding: 11px 13px;
	cursor: pointer;
	border-radius: 17px;
	background: #31BBA233;
	width: 223px;
	margin: 10px;
	&:hover {
		box-shadow: 0px 4px 4px 0px rgba(49, 187, 162, 0.5); }
	.text {
		color: #179E85; }
	&.selected {
		background-color: $turquoise;
		.text {
			color: $white; } }
	input {
    	position: absolute;
    	opacity: 0;
    	cursor: pointer;
    	height: 0;
    	width: 0; }
	.custom_img {
		background: $white;
		border-radius: 10px;
		padding: 5px;
		width: fit-content;
		margin-right: 15px; } }
.block7 {
	span {
		display: none; }
	.custom-radio {
		width: 100px;
		padding: 0;
		border: 3px solid $pale;
		margin: 5px;
		@include r(500) {
			width: 87px; }
		&.selected {
			border: 3px solid $turquoise; }
		img {
			width: 100%;
			height: 100%;
			border-radius: 13px;
			object-fit: none; } }
	.text {
		margin-left: 10px; } }
.textblock7 {
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	color: $black;
	margin: 0px 10px 30px;
	max-width: 540px;
	@include r(950) {
		margin: 0;
		padding: 30px 20px 60px;
		background: $pale;
		border-radius: 30px;
		margin-top: -60px;
		z-index: 1; } }
.block8 {
	.custom-radio {
		width: 117px;
		justify-content: center;
		padding: 15px;
		border-radius: 15px;
		@include r(950) {
			width: 184px; } }
	.textblock7 {
		margin-top: 0;
		max-width: 100%;
		@include r(950) {
			margin-top: -60px; } } }
.block9 {
	margin-top: 70px;
	@include r(950) {
		margin-top: 0; }
	.btnback {
		margin-top: 70px;
		@include r(950) {
			margin-top: 30px; } }
	.text {
		max-width: 467px;
		margin: 0 0 40px 10px; }
	.texttop {
		@include r(950) {
			display: none; } }
	.main_block1 {
		@include r(950) {
			padding-top: 20px; } }
	.flow_abs {
		@include r(950) {
			right: auto;
			left: -30%; } } }
.confirmation {
	display: flex;
	margin-top: 40px;
	position: relative;
	.error_text {
		left: 30px; }
	.text {
		font-size: 14px;
		margin: 0 10px;
		a {
			color: $turquoise; } }
	.check {
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0; }
		span {
			width: 24px;
			height: 24px;
			background: $white;
			display: block;
			border-radius: 2px;
			border: 1.5px solid #D9DFE5;
			cursor: pointer; }
		&.selected {
			span {
				background: url(../img/svg/check.svg) no-repeat center,  $white; } } } }
.iti {
	width: 100%; }
.iti__arrow {
	width: 7px;
	height: 7px;
	border: 1.5px solid #061B2E;
	border-left: none;
	border-top: none;
	transform-origin: center;
	transform: rotate(45deg);
	transition: transform .1s;
	margin-top: -3px;
	margin-left: 8px; }
.iti__selected-flag {
	border-right: 1px solid #D5DBE0;
	padding: 0px 10px; }
.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
	padding-left: 65px; }
.iti__arrow--up {
	transform: rotate(-135deg);
	margin-top: 5px; }
.error {
	border: 1px solid #FF0000;
	color: #FF0000;
	&::placeholder {
		color: #FF0000; } }
.error_text {
	position: absolute;
	bottom: -20px;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	color: #FF0000;
	display: none;
	&.active {
		display: block; } }
.hide {
	display: none; }
.block9_main {
	z-index: 1; }
.mobblock {
	display: block!important; }
.mobnone {
	display: none!important; }
.title_desk {
	@include r(950) {
		display: none; } }
.title_mob {
	display: none;
	@include r(950) {
		display: block; } }
.block9 {
	.textmob {
		display: none;
		margin-left: 0px;
		margin-bottom: 20px;
		@include r(950) {
			display: block; } } }
.title10 {
	@include r(950) {
		position: absolute;
		top: 20px; } }

