footer {
	background: linear-gradient(180deg, #EAEEF2 0%, #F3F3F3 100%);
	.container {
		display: flex;
		justify-content: space-between;
		padding: 80px 130px;
		@include r(1200) {
			padding: 40px; }
		@include r(540) {
			flex-direction: column; } }
	.logo_block {
		img {
			max-width: 168px;
			@include r(870) {
				max-width: 147px; } } }
	.link_footer {
		display: flex;
		width: 580px;
		justify-content: space-between;
		@include r(870) {
			justify-content: end; }
		@include r(540) {
			justify-content: start;
			width: 100%;
			margin-top: 30px; }
		li {
			padding: 10px 0;
			a {
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;
				color: $black;
				text-decoration: none;
				&:hover {
					color: $turquoise; } } } }
	.colum-1 {
		@include r(870) {
			display: none; } }
	.colum-2 {
		@include r(870) {
			display: none; } }
	.colum-3 {
		a {
			display: flex; }
		span {
			margin-left: 10px; } }
	.bottom_footer {
		background: $black;
		ul {
			display: flex;
			justify-content: center;
			@include r(870) {
				flex-direction: column;
				align-items: center;
				padding: 16px; }
			li {
				font-size: 16px;
				font-weight: 400;
				line-height: 18.75px;
				color: $white;
				padding: 20px;
				@include r(870) {
					padding: 5px; }
				a {
					font-size: 16px;
					font-weight: 400;
					line-height: 18.75px;
					color: $white;
					&:hover {
						text-decoration: none; } } } } } }


