.select2-selection--single {
	background-color: #fff;
	border: 1px solid #D5DBE0;
	border-radius: 4px;
	.select2-selection--single:focus{
		border: 1px solid #31BBA2;
	}
	.app-select--selected ~ & {
		border-color:#31BBA2;
	}
	.app-select--selected ~ & .select2-selection__rendered{
		color: #061B2E;

	}
	.select2-selection__rendered {
		font-size: 16px;
		font-weight: 400;
		line-height: 46px;
		color: $gray

	}

	.select2-selection__clear {
		cursor: pointer;
		float: right;
		font-weight: bold;
		height: 46px;
		margin-right: 20px;
		padding-right: 0px;
	}
	.select2-selection__arrow {
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		right: 15px;
		border: 1.5px solid #061B2E;
		border-left: none;
		border-top: none;
		transform-origin: center;
		transform: rotate(-45deg);
		transition: transform .1s;
		margin-top: -5px;
		b {
			display: none;
		}
	}
}
&[dir="rtl"] {
	.select2-selection--single {
		.select2-selection__clear {
			float: left;
		}

		.select2-selection__arrow {
			left: 1px;
			right: auto;
		}
	}
}

&.select2-container--disabled {
	.select2-selection--single {
		background-color: #eee;
		cursor: default;

		.select2-selection__clear {
			display: none;
		}
	}
}

&.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			transform: rotate(45deg);
			margin-top: -5px;
		}
	}
}
