header {
	background: white;
	z-index: 100;
	border-bottom: 2px solid #EAECEE;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	.wrap {
		max-width: 1480px;
		margin: auto;
		padding: 26px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include r(1600) {
			padding: 26px 100px; }
		@include r(969) {
			padding: 10px 20px; } }
	.header_logo {
		img {
			@include r(969) {
				width: 114px; } } }
	.nav_block {
		@include r(1020) {
			display: none; }
		ul {
			display: flex;
			justify-content: space-between;
			li {
				padding: 0px 20px; }
			a {
				color: $black;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;
				text-decoration: none;
				&:hover {
					color: $turquoise; } } } } }
.language {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
	color: $black;
	display: flex;
	align-items: center;
	&:hover {
		color: $turquoise; }
	&:hover:after {
		border-color: $turquoise; }
	&:after {
		content: "";
		width: 5px;
		height: 5px;
		border: 1.5px solid #061B2E;
		border-left: none;
		border-top: none;
		transform-origin: center;
		transform: rotate(45deg);
		transition: transform .1s;
		margin-left: 5px;
		margin-top: -3px;
		display: block; }
	&.active {
		&:after {
			transform: rotate(-135deg);
			margin-top: 3px; } }
	&__active {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 5px; }
	&__list {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background: $white;
		padding: 10px;
		width: 154px;
		border-radius: 10px;
		border: 1px solid #D5DBE0;
		@include r(969) {
			left: -75px; }
		&.active {
			display: block; } }
	&__link {
		text-decoration: none;
		display: block;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.3;
		color: #A1A1A1;
		margin-top: 6px;
		&.active {
			color: $turquoise;
			display: flex;
			align-items: center;
			justify-content: space-between;
			&:after {
				content: "";
				width: 12px;
				height: 12px;
				background: url(../img/svg/check.svg) no-repeat center;
				display: block; } }
		&:hover {
			color: $turquoise; } } }
